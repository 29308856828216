/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Gracias a ", React.createElement(_components.a, {
    href: "http://arrsa.org/",
    title: "aRRsa"
  }, "aRRsa"), " he descubierto ", React.createElement(_components.a, {
    href: "http://arrsa.org/2012/07/los-tres-movimientos-del-metodo-que-permiten-una-conversacion-fluida/"
  }, "el método Livingstone"), ", una estrategia de interacción con los clientes usada en arquitectura. Continúo con mi idea de buscar asociaciones de técnicas y métodos entre diferentes ámbitos como ya hice en el caso del ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2012/06/16/mantenimiento-proactivo-y-desarrollo-agil/"
  }, "mantenimiento proactivo y el desarrollo ágil"), "."), "\n", React.createElement(_components.p, null, "En este caso la asociación es inmediata, el método Livigstone se corresponde claramente con lo que en desarrollo software se corresponde con las ", React.createElement(_components.a, {
    href: "http://www.genbetadev.com/metodologias-de-programacion/historias-de-usuario-una-forma-natural-de-analisis-funcional"
  }, "historias de usuario"), " del desarrollo ágil."), "\n", React.createElement(_components.p, null, "Concretamente en el manifiesto ágil podemos ver los siguiente principios:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "A los ", React.createElement(_components.strong, null, "individuos y su interacción"), ", por encima de los procesos y las herramientas."), "\n", React.createElement(_components.p, null, "La ", React.createElement(_components.strong, null, "colaboración con el cliente"), ", por encima de la negociación contractual."), "\n"), "\n", React.createElement(_components.p, null, "Estos principios concuerdan perfectamente con el tema que tratamos, una nueva forma de hacer los proyectos más encarada al mundo actual."), "\n", React.createElement(_components.p, null, "Tal y como el desarrollo software puso en alza el software libre y ahora empezamos a ver esta ideología fuera del ámbito del software, como el hardware libre, también podemos esperar que otras ideas como las del desarrollo ágil salgan de su cuna y aparezcan en lugares tan dispares como la arquitectura o la industria."), "\n", React.createElement(_components.p, null, "¿Será necesario la aplicación de las historias de usuario en el desarrollo de la industria? ¿La haria mas productiva? ¿Más competitiva?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
